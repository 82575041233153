export const CREATE_LINE_ITEM = `
mutation createLineItem($input: CreateLineItemInput!) {
    createLineItem(input: $input) {
        id
		sku
		name
		quantity
		product {
			id
			sku
			name
			description
			mainImageUrl
			imageUrls
		}
		productVariant {
			id
			sku
			name
			inventory
			amount {
				value
			}
		}
		provider {
			id
			name
			coverages {
				id
				name
			}
		}
  }
}
`;
