import React, { createContext } from 'react';
import { HomeProduct, ProductDetails } from '../../types/productTypes';

export type ProductContextProps = {
	productDetails: ProductDetails;
	getProducts: Function;
	getListProducts: Function;
	getFilterProducts: Function;
};

const ProductContext = createContext<ProductContextProps>({} as ProductContextProps);

export default ProductContext;
