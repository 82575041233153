import React, { useContext, useState } from 'react';
import FilterContext from './filter-context';
import { FilterInput } from '../../types/filterTypes';

interface props {
	children: JSX.Element | JSX.Element[];
}

const FilterProvider = ({ children }: props) => {
	const [filters, setFilters] = useState<FilterInput>({
		providerId: [],
		state: [],
		city: [],
		brand: [],
		category: [],
		activeIngredient: [],
		cropType: [],
		minPrice: null,
		maxPrice: null,
	});

	return (
		<FilterContext.Provider value={{ filters, setFilters }}>{children}</FilterContext.Provider>
	);
};

export default FilterProvider;
