type ErrorMessage = {
	[es: string]: string;
};

export const cognitoErrorMessages: Record<string, ErrorMessage> = {
	'Incorrect username or password.': { es: 'El usuario o la contraseña son incorrectos' },
	'Password attempts exceeded': {
		es: 'Se superó el límite de intentos, inténtelo después de un tiempo',
	},
	'User does not exist.': {
		es: 'El usuario o la contraseña son incorrectos',
	},
	'Username/client id combination not found.': {
		es: 'No se encontró al usuario, verifique los datos ingresados',
	},
	'Attempt limit exceeded, please try after some time.': {
		es: 'Se superó el límite de intentos, inténtelo después de un tiempo',
	},
	'Invalid verification code provided, please try again.': {
		es: 'El código de verificación es incorrecto, inténtelo de nuevo',
	},
	'Password does not conform to policy: Password not long enough': {
		es: 'La longitud de la contraseña debe ser de al menos 8 caracteres',
	},
	'Password does not conform to policy: Password must have numeric characters': {
		es: 'La contraseña debe contener al menos un carácter numérico',
	},
	'Password does not conform to policy: Password must have lowercase characters': {
		es: 'La contraseña debe contener al menos una letra minúscula',
	},
	'Invalid code provided, please request a code again.': {
		es: 'El código de verificación es inválido, solicite un nuevo código.',
	},
	'User already exists': { es: 'El nombre de usuario ya esta en uso, pruebe con uno distinto' },
	'An account with the email already exists.': {
		es: 'El correo ya esta en uso, pruebe con uno distinto',
	},
	'PreSignUp failed with error An account with the contact info already exists.': {
		es: 'La información de contacto (email/teléfono) ya está en uso, pruebe con una distinta',
	},
	GENERIC_ERROR: { es: 'Se ha producido un error, inténtelo más tarde' },
};
