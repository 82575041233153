import React from 'react';
import { navigate } from 'gatsby';
import { resetPassword, type ResetPasswordOutput } from 'aws-amplify/auth';
import { handleCognitoError } from '../utils/index';
import { UserErrors } from '../types/userTypes';

const useAuthProvider = (
	nextStep: string,
	username: string,
	setErrorMessage: Function,
	setErrorAlert: Function,
) => {
	switch (nextStep) {
		case 'RESET_PASSWORD':
			handleResetPassword(username, setErrorMessage, setErrorAlert);
			break;

		case 'DONE':
			return navigate('/');

		default:
			break;
	}
};

const handleResetPassword = async (
	username: string,
	setErrorMessage: Function,
	setErrorAlert: Function,
) => {
	try {
		const output = await resetPassword({ username });
		handleResetPasswordNextSteps(output, username);
	} catch (error) {
		console.log(error);

		setErrorAlert(true);
		if ((error as Error)?.message !== undefined) {
			setErrorMessage(handleCognitoError(error as UserErrors));
		} else {
			setErrorMessage(handleCognitoError('GENERIC_ERROR'));
		}
	}
};
const handleResetPasswordNextSteps = (output: ResetPasswordOutput, username: string) => {
	const { nextStep } = output;
	switch (nextStep.resetPasswordStep) {
		case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
			return navigate(`/migracion-de-usuario/${username}`);

		case 'DONE':
			return navigate('/');
	}
};

export default useAuthProvider;
