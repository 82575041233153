import React, { createContext } from 'react';
import { type SignUpContextProps } from 'types/signUpTypes';

const SignUpContext = createContext<SignUpContextProps>({
	signUpValues: {
		firstName: '',
		lastName: '',
		document: '',
		email: '',
		phone: '',
		country: '',
		agronomist: '',
		username: '',
		password: '',
		confirmPassword: '',
	},
	setSignUpValues: () => {},
	isSignUpSubmitted: false,
	setIsSignUpSubmitted: () => {},
	isLoading: false,
	setIsLoading: () => {},
	isDisabled: false,
	setIsDisabled: () => {},
	handleSignUp: () => {},
	errorMessage: '',
	setErrorMessage: () => {},
	showErrorAlert: false,
	setErrorAlert: () => {},
	showAlert: false,
	setShowAlert: () => {},
	handleConfirmSignUp: () => {},
	resendVerificationCode: () => {},
});

export default SignUpContext;
