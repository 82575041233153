import { Category, CategoryState } from '../../types/categoryTypes';

type CategoryAction = {
	type: 'GET_CATEGORIES';
	payload: Array<Category>;
};

const categoryReducer = (globalState: CategoryState, action: CategoryAction): CategoryState => {
	switch (action.type) {
		case 'GET_CATEGORIES':
			return {
				...globalState,
				categories: action.payload,
			};

		default:
			return globalState;
	}
};
export default categoryReducer;
