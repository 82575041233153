import React, { useContext, useState } from 'react';
import { signUp, confirmSignUp, resendSignUpCode } from 'aws-amplify/auth';
import { navigate } from 'gatsby';
import SignUpContext from './signup-context';
import { RegisterFormValues } from '../../components/register/register-validation.schema';
import { UserErrors } from '../../types/userTypes';
import { handleCognitoError, handleLoginSession } from '../../utils';
import { phoneCountryCodes } from '../../constants';
import UserContext from '../user/user-context';

interface props {
	children: JSX.Element | JSX.Element[];
}

const SignUpProvider = ({ children }: props) => {
	const { getCurrentSession } = useContext(UserContext);
	const [signUpValues, setSignUpValues] = useState<RegisterFormValues>({
		firstName: '',
		lastName: '',
		document: '',
		email: '',
		phone: '',
		country: '',
		agronomist: '',
		username: '',
		password: '',
		confirmPassword: '',
	});
	const [isSignUpSubmitted, setIsSignUpSubmitted] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [showErrorAlert, setErrorAlert] = useState<boolean>(false);
	const [showAlert, setShowAlert] = useState<boolean>(false);

	const handleSignUp = async (data: RegisterFormValues) => {
		setIsLoading(true);
		setIsDisabled(true);
		const { username, password, email, phone, country } = data;
		const phoneWithCode = `${phoneCountryCodes[country]}${phone}`;
		try {
			const { nextStep } = await signUp({
				username,
				password,
				options: {
					userAttributes: {
						email,
						phone_number: phoneWithCode,
						'custom:role': 'user',
					},
				},
			});

			switch (nextStep.signUpStep) {
				case 'CONFIRM_SIGN_UP':
					setSignUpValues(data);
					setErrorMessage('');
					setErrorAlert(false);
					setIsSignUpSubmitted(true);
					setIsLoading(false);
					setIsDisabled(false);
					navigate('/registro/verificacion');
					break;
				case 'DONE':
					handleLoginSession(
						{ username, password },
						setErrorMessage,
						setErrorAlert,
						getCurrentSession,
					);
					break;
				default:
					break;
			}
		} catch (error) {
			if ((error as Error)?.message !== undefined) {
				setErrorMessage(handleCognitoError(error as UserErrors));
			} else {
				setErrorMessage(handleCognitoError('GENERIC_ERROR'));
			}
			setErrorAlert(true);
			setIsDisabled(false);
			setIsLoading(false);
			setIsSignUpSubmitted(false);
		}
	};

	const handleConfirmSignUp = async (data: { confirmationCode: string }) => {
		setIsLoading(true);
		setIsDisabled(true);
		const { username, firstName, lastName, document, country, agronomist, password } =
			signUpValues;
		if (username && username.length > 0) {
			try {
				const { nextStep } = await confirmSignUp({
					username,
					confirmationCode: data.confirmationCode,
					options: {
						clientMetadata: {
							firstName,
							lastName,
							document: document ? document : '',
							country,
							agronomist: agronomist ? agronomist : '',
						},
					},
				});
				switch (nextStep.signUpStep) {
					case 'CONFIRM_SIGN_UP':
						setIsSignUpSubmitted(true);
						setIsLoading(false);
						setIsDisabled(false);
						break;
					case 'DONE':
						setShowAlert(true);
						setIsLoading(false);
						setIsDisabled(false);
						handleLoginSession(
							{ username, password },
							setErrorMessage,
							setErrorAlert,
							getCurrentSession,
						);
						break;
					default:
						break;
				}
			} catch (error) {
				if ((error as Error)?.message !== undefined) {
					setErrorMessage(handleCognitoError(error as UserErrors));
				} else {
					setErrorMessage(handleCognitoError('GENERIC_ERROR'));
				}
				setErrorAlert(true);
				setIsDisabled(false);
				setIsLoading(false);
				setIsSignUpSubmitted(true);
			}
		}
	};

	const resendVerificationCode = async () => {
		setIsDisabled(true);
		const { username } = signUpValues;
		if (username && username.length > 0) {
			try {
				const result = await resendSignUpCode({ username });
				console.log(JSON.stringify(result, null, 2));
			} catch (error) {
				if ((error as Error)?.message !== undefined) {
					setErrorMessage(handleCognitoError(error as UserErrors));
				} else {
					setErrorMessage(handleCognitoError('GENERIC_ERROR'));
				}
				setErrorAlert(true);
				setIsDisabled(false);
			}
		}
		setIsDisabled(false);
	};

	return (
		<SignUpContext.Provider
			value={{
				signUpValues,
				setSignUpValues,
				isSignUpSubmitted,
				setIsSignUpSubmitted,
				isLoading,
				setIsLoading,
				handleSignUp,
				isDisabled,
				setIsDisabled,
				errorMessage,
				setErrorMessage,
				showErrorAlert,
				setErrorAlert,
				showAlert,
				setShowAlert,
				handleConfirmSignUp,
				resendVerificationCode,
			}}
		>
			{children}
		</SignUpContext.Provider>
	);
};

export default SignUpProvider;
