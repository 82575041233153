import { UserDetails, UserGeolocation, UserState } from '../../types/userTypes';

type UserAction =| {
	type: 'GET_CURRENT_USER';
	payload: UserDetails;
}|
{
	type: 'GET_GEOLOCATION_USER';
	payload: UserGeolocation;
};

const userReducer = (globalState: UserState, action: UserAction): UserState => {
	switch (action.type) {
		case 'GET_CURRENT_USER':
			return {
				...globalState,
				UserDetails: action.payload,
			};
		case 'GET_GEOLOCATION_USER':
			return {
				...globalState,
				UserGeolocation: action.payload,
			};

		default:
			return globalState;
	}
};
export default userReducer;
