import React, { Fragment } from 'react';
import { ThemeUIProvider } from 'theme-ui';
import { Global } from '@emotion/react';
import CartProvider from '../../provider/cart-provider';
import LocalCartProvider from '../../provider/local-cart-provider';
import SearchProvider from '../../provider/search-provider';
import theme from '../../gatsby-plugin-theme-ui';
import styles from './layout.style';
import ProductProvider from '../../provider/products/products-provider';
import CategoryProvider from '../../provider/categories/category-provider';
import UserProvider from '../../provider/user/user-provider';
import CheckoutProvider from '../../provider/checkout/checkout-provider';
import OrderProvider from '../../provider/orders/order-provider';
import FilterProvider from '../../provider/filters/filter-provider';
import SignUpProvider from '../../provider/signUp/signup-provider';
interface props {
	children: JSX.Element | JSX.Element[];
}

const Layout = ({ children }: props) => {
	return (
		<ThemeUIProvider theme={theme}>
			<LocalCartProvider>
				<UserProvider>
					<CheckoutProvider>
						<OrderProvider>
							<CategoryProvider>
								<ProductProvider>
									<FilterProvider>
										<CartProvider>
											<SearchProvider>
												<SignUpProvider>
													<Fragment>
														<Global styles={styles} />
														{children}
													</Fragment>
												</SignUpProvider>
											</SearchProvider>
										</CartProvider>
									</FilterProvider>
								</ProductProvider>
							</CategoryProvider>
						</OrderProvider>
					</CheckoutProvider>
				</UserProvider>
			</LocalCartProvider>
		</ThemeUIProvider>
	);
};

export default Layout;
