import React, { createContext, useState, useMemo } from 'react';

export const SearchContext = createContext(null);
interface props {
	children: JSX.Element | JSX.Element[];
}
const SearchProvider = ({ children }: props) => {
	const [isSearched, setIsSearched] = useState(false);
	const value: any = useMemo(() => ({ isSearched, setIsSearched }), [isSearched, setIsSearched]);

	return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

export default SearchProvider;
