//@ts-nocheck
import React, { useState, useEffect } from 'react';
import Client from 'shopify-buy';
import { getLocalState } from '../helper/local-storage';
import { CART_PRODUCTS } from './local-cart-provider';

export const CartContext = React.createContext();

const CartProvider = ({ children }) => {
	let initialStoreState = {
		updating: false,
		checkout: { lineItems: [] },
		products: [],
	};

	const [store, updateStore] = useState(initialStoreState);
	const [initialize, setIntialize] = useState(false);

	useEffect(() => {
		const initializeCheckout = async () => {
			const setCheckoutInState = (checkout) => {
				updateStore((prevState) => {
					return { ...prevState, checkout };
				});
			};
		};

		initializeCheckout();
	}, [initialize]);

	return (
		<CartContext.Provider
			value={{
				addToCartAndCheckout: async () => {
					updateStore((prevState) => {
						return { ...prevState, updating: true };
					});
					const products = getLocalState(CART_PRODUCTS);
					const lineItemsToUpdate = [];

					products.forEach((product) => {
						lineItemsToUpdate.push({
							variantId: product.variantId,
							quantity: product.quantity,
						});
					});
					let windowReference = window.open();

					return client.checkout
						.addLineItems(checkoutId, lineItemsToUpdate)
						.then((checkout) => {
							windowReference.location = checkout.webUrl;
							setIntialize(Date.now());
							updateStore((prevState) => {
								return {
									...prevState,
									checkout,
									updating: false,
								};
							});
						});
				},
			}}
		>
			{children}
		</CartContext.Provider>
	);
};

export default CartProvider;
