/** @jsx jsx */
import { Children, useContext, useEffect, useState } from 'react';
import { Box, Flex, Heading, Text, Button, jsx, Alert, Close } from 'theme-ui';
import { IoIosClose } from 'react-icons/io';
import styles from './cart.style';
import { LocalCartContext } from '../../provider/local-cart-provider';
import LineItem from './line-item/line-item';
import { navigate } from 'gatsby';
import { ProductLineItem } from '../../types/productTypes';
import UserContext from '../../provider/user/user-context';
import CheckoutContext from '../../provider/checkout/checkout-context';
import { getLocalState, setLocalState } from '../../helper/local-storage';
import { getPrice } from '../../utils/index';

type Props = {
	setIsOpen: Function;
	isOpen: boolean;
};

export const CHECKOUT_ID = 'checkoutId';
const Cart = ({ setIsOpen, isOpen }: Props) => {
	const { products, totalPrice, currency } = useContext(LocalCartContext);
	const [showErrorAlert, setErrorAlert] = useState(false);
	const [errorMessages, setMessages] = useState('');

	const handleCheckout = async () => {
		try {
			setIsOpen(!isOpen);
			navigate('/checkout');
		} catch (error) {
			setMessages('Hubo un error al agregar los productos al carrito');
			setErrorAlert(true);
			throw new Error(String(error));
		}
	};

	return (
		<Box sx={styles.wrapper}>
			{showErrorAlert && (
				<Alert variant="highlight">
					{errorMessages}
					<Close ml="auto" mr={-2} onClick={() => setErrorAlert(false)} />
				</Alert>
			)}
			<Heading as="h2" sx={styles.title}>
				Tu carrito
				<Button title="Close" variant="text" onClick={() => setIsOpen(!isOpen)}>
					<IoIosClose />
				</Button>
			</Heading>
			<Flex sx={styles.totalPrice}>
				<Box>
					<Text>Total:</Text>
					<Heading as="h4">{getPrice(totalPrice, currency)}</Heading>
				</Box>
			</Flex>

			<Box sx={styles.cartItems}>
				<Box>
					{products.length > 0 ? (
						products.map((item) => (
							<LineItem key={`product-cart-${item.productVariantId}`} item={item} />
						))
					) : (
						<Box>
							<Text as="h3">No hay artículos en su carrito</Text>
							<Text as="p">Parece que aún no has hecho tu orden.</Text>
						</Box>
					)}
				</Box>
			</Box>

			<Box sx={styles.buttonStyle}>
				{products.length === 0 ? (
					<Button onClick={() => setIsOpen(!isOpen)}>
						<svg width="15.394" height="10" viewBox="0 0 15.394 10">
							<path
								id="arrow-left"
								d="M8.3,6.343,9.551,7.6,6.66,10.473l12.025.012,0,1.768L6.691,12.241,9.533,15.1,8.28,16.343,3.292,11.332Z"
								transform="translate(-3.292 -6.343)"
								fill="#fff"
							/>
						</svg>
						Regresar a la tienda
					</Button>
				) : (
					<Button onClick={() => handleCheckout()}>Ir al checkout</Button>
				)}
			</Box>
		</Box>
	);
};

export default Cart;
