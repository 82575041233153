import { ProductDetails, ProductState } from '../../types/productTypes';

type ProductAction = {
	type: 'GET_PRODUCTS';
	payload: ProductDetails;
};

const productReducer = (globalState: ProductState, action: ProductAction): ProductState => {
	switch (action.type) {
		case 'GET_PRODUCTS':
			return {
				...globalState,
				ProductDetails: action.payload,
			};

		default:
			return globalState;
	}
};
export default productReducer;
