export const GET_PROVIDER = `
query getProviderById($id: ID!) {
	getProviderById(id: $id) {
		id
		name
		coverages {
			id
			name
		}
		address {
			street
			streetNumber
			internalNumber
			neighborhood
			city
			state
			country
			zipCode
			geolocation {
				lat
				lng
			}
		}
		email
		phone
	}
}
`;
