import React, { useReducer } from 'react';
import { APPLY_FILTERS } from '../../helper/get-products';
import productReducer from './product-reducer';
import ProductContext from './product-context';
import { FilterInput } from '../../types/filterTypes';
import { ProductState, HomeProduct, ProductDetails } from '../../types/productTypes';

interface props {
	children: JSX.Element | JSX.Element[];
}

const ProductProvider = ({ children }: props) => {
	const initialState: ProductState = {
		ProductDetails: {
			id: '',
			sku: '',
			name: '',
			description: '',
			provider: '',
			amount: {
				value: '',
				currency: '',
			},
			inventory: 0,
			product: {
				id: '',
				name: '',
				description: '',
				mainImageUrl: '',
				imageUrls: [],
				image: '',
			},
		},
	};

	const [globalState, dispatch] = useReducer(productReducer, initialState);

	//GET PRODUCTS
	const getProducts = async (productId: string, variants: HomeProduct[]) => {
		const variant = variants.find((variant) => variant.id === productId);
		dispatch({
			type: 'GET_PRODUCTS',
			payload: variant as unknown as ProductDetails,
		});
		return variant
	};

	const getListProducts = (variants: HomeProduct[]) => {
		try {
			const randomProducts = variants.sort(() => 0.5 - Math.random());

			const homeProducts = randomProducts.slice(0, 15);
			return homeProducts;
		} catch (error) {
			console.log(error);
		}
	};

	const getFilterProducts = async (input: FilterInput) => {
		const resApplyFilters = await window.fetch(`${process.env.GATSBY_API_URL}/products/`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: APPLY_FILTERS,
				variables: {
					input,
				},
			}),
		});
		const listFilterProducts = await resApplyFilters.json();
		return listFilterProducts?.data?.applyFilters;
	};

	return (
		<ProductContext.Provider
			value={{
				productDetails: globalState.ProductDetails,
				getProducts,
				getListProducts,
				getFilterProducts,
			}}
		>
			{children}
		</ProductContext.Provider>
	);
};

export default ProductProvider;
