import React, { useContext, useEffect, useState } from 'react';
import Currency from 'currency.js';
import { getLocalState, setLocalState, removeLocalStateItem } from '../helper/local-storage';
import { CartProduct } from '../types/productTypes';
import { CHECKOUT_ID } from '../components/cart/cart';
import UserContext from '../provider/user/user-context';

export const CART_PRODUCTS = 'cartProducts';
export const TOTAL_PRICE = 'totalPrice';
export const CURRENCY = 'currency';

interface LocalCartContextProps {
	products: CartProduct[];
	addToCart: Function;
	updateCart: Function;
	clearCart: Function;
	totalPrice: number;
	currency: string;
	getCartProducts: Function;
	isQuantityEditing: boolean;
	quantity: number;
	tempQuantity: number;
	setIsQuantityEditing: React.Dispatch<React.SetStateAction<boolean>>;
	setQuantity: React.Dispatch<React.SetStateAction<number>>;
	setTempQuantity: React.Dispatch<React.SetStateAction<number>>;
}

export const LocalCartContext = React.createContext({} as LocalCartContextProps);

const getCartProducts = () => {
	const products = getLocalState(CART_PRODUCTS);
	return products ? products : [];
};

const getTotalPrice = () => {
	const price = getLocalState(TOTAL_PRICE);
	return price ? price : 0;
};

const getCurrency = () => {
	const currency = getLocalState(CURRENCY);
	return currency ? currency : '';
};

const calculateTotalPrice = (products: CartProduct[]): number => {
	let total = Currency(0);
	let finalTotal;
	products.forEach((product) => {
		const quantity = product.quantity ? product.quantity : 1;
		const price = product.amount.value ? product.amount.value : '0.0';
		const itemPrice = Currency(quantity).multiply(price);
		total = Currency(total).add(itemPrice);
	});
	finalTotal = Number(total.value);
	return finalTotal;
};

const LocalCartProvider = ({ children }: any) => {
	const { getCurrentSession } = useContext(UserContext);

	const [products, setProducts] = useState<CartProduct[]>([]);
	const [totalPrice, setTotalPrice] = useState(0);
	const [currency, setCurrency] = useState('');

	// Handle edition across components
	const [isQuantityEditing, setIsQuantityEditing] = useState(false);
	const [quantity, setQuantity] = useState(0);
	const [tempQuantity, setTempQuantity] = useState(0);

	React.useEffect(() => {
		setProducts(getCartProducts());
		setTotalPrice(getTotalPrice());
		setCurrency(getCurrency());
	}, []);

	useEffect(() => {
		getCurrentSession;
	}, []);

	const setPrice = () => {
		const price = calculateTotalPrice(products);
		setLocalState(TOTAL_PRICE, price);
		setTotalPrice(price);
	};

	const setCurrencyData = () => {
		const currency = products && products.length ? products[0].amount.currency : '';
		setLocalState(CURRENCY, currency);
		setCurrency(currency);
	};

	const addToCart = async (item: CartProduct, setShowAlert: Function) => {
		// TODO: cookie storage
		if (products.length) {
			const index = products.findIndex(
				(product: CartProduct) => product.product.productId === item.product.productId,
			);
			const checkoutId = (await getLocalState(CHECKOUT_ID)) || '';
			if (checkoutId !== '') {
				removeLocalStateItem(CHECKOUT_ID);
			}

			const providerIndex = products.findIndex(
				(product: CartProduct) => product.providerId === item.providerId,
			);

			if (providerIndex === -1) {
				setShowAlert(true);
			} else {
				if (index !== -1) {
					// if product already available in the cart
					const product = products[index];
					const quantity = product.quantity ? product.quantity : 0;
					products[index] = { ...product, ...item, quantity: quantity + 1 }; // just increase the quantity
				} else {
					// if this product is not available in the cart
					products.push({ ...item, quantity: 1 });
				}
			}
		} else {
			// if the cart is empty
			products.push({ ...item, quantity: 1 });
		}
		setLocalState(CART_PRODUCTS, products);
		setProducts([...products]);
		setPrice();
		setCurrencyData();
	};

	const updateCart = (variantId: string, quantity: number) => {
		const index = products.findIndex(
			(product: CartProduct) => product.productVariantId === variantId,
		);

		if (quantity < 1 && index > -1) {
			// delete if quantity, 0
			products.splice(index, 1);
			if (products.length === 0) {
				setLocalState(CHECKOUT_ID, '');
			}
		} else {
			// update quantity
			const product = products[index];
			products[index] = { ...product, quantity };
		}
		setLocalState(CART_PRODUCTS, products);
		setProducts([...products]);
		setPrice();
	};

	const clearCart = () => {
		setLocalState(CART_PRODUCTS, []);
		setLocalState(TOTAL_PRICE, 0);
		setProducts([]);
		setTotalPrice(0);
		setLocalState(CHECKOUT_ID, '');
	};

	return (
		<LocalCartContext.Provider
			value={{
				products,
				addToCart,
				updateCart,
				totalPrice,
				currency,
				clearCart,
				getCartProducts,
				isQuantityEditing,
				setIsQuantityEditing,
				quantity,
				setQuantity,
				tempQuantity,
				setTempQuantity,
			}}
		>
			{children}
		</LocalCartContext.Provider>
	);
};

export default LocalCartProvider;
