/* eslint-disable no-mixed-spaces-and-tabs */
import { Order, OrderState } from '../../types/orderTypes';

type OrderAction =
	| {
			type: 'CREATE_ORDER';
			payload: Order;
	  }

const orderReducer = (globalState: OrderState, action: OrderAction): OrderState => {
	switch (action.type) {
		case 'CREATE_ORDER':
			return {
				...globalState,
				OrderDetails: action.payload,
			};

		default:
			return globalState;
	}
};
export default orderReducer;
