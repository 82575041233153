import React, { createContext } from 'react';
import { FilterInput } from '../../types/filterTypes';

export type FilterContextProps = {
	filters: FilterInput;
	setFilters: React.Dispatch<React.SetStateAction<FilterInput>>;
};

const FilterContext = createContext<FilterContextProps>({
	filters: {
		providerId: [],
		state: [],
		city: [],
		brand: [],
		category: [],
		activeIngredient: [],
		cropType: [],
		minPrice: null,
		maxPrice: null,
	},
	setFilters: () => {},
});

export default FilterContext;
