import { createContext } from 'react';
import { UserDetails, UserGeolocation } from '../../types/userTypes';

export type UserContextProps = {
	userDetails: UserDetails;
	getCurrentSession: Function;
	getUserAddress: Function;
	handleSignOut: Function;
	messageSessionExpired: string;
	isSessionExpired: boolean;
	userGeolocation: UserGeolocation;
	createUserAddress: Function;
	createBillingAddress: Function;
	createShippingAddress: Function;
	updateUserData: Function;
	uploadAvatar: Function;
};

const UserContext = createContext<UserContextProps>({} as UserContextProps);

export default UserContext;
