import { createContext } from 'react';

export type OrderContextProps = {
	createOrder: Function;
	getOrder: Function;
	getOrdersByUser: Function;
};

const OrderContext = createContext<OrderContextProps>({} as OrderContextProps);
export default OrderContext;
