export const CREATE_CHECKOUT = `
mutation createCheckout($input: CreateCheckoutInput!) {
    createCheckout(input: $input) {
        id
		user {
			id
			email
			phone
			firstName
			lastName
            document
		}
		billingAddress {
			address {
				id
				name
				street
				streetNumber
				internalNumber
				neighborhood
				city
				state
				country
				zipCode
				geolocation {
					lat
					lng
				}
			}
			legalName
			taxId
		}
		shippingAddress {
			address {
				id
				name
				street
				streetNumber
				internalNumber
				neighborhood
				city
				state
				country
				zipCode
				geolocation {
					lat
					lng
				}
			}
			email
			phone
		}
		lineItems {
			name
			sku
			product {
				id
				name
				description
                mainImageUrl
			}
			productVariant {
				id
				name
				amount {
					value
				}
			}
			quantity
			provider {
				id
				name
			}
		}
		totalAmount {
			value
		}
		discounts {
			id
			code
			amount {
				value
			}
		}
		discountedAmount {
			value
		}
		originalAmount {
			value
		}
		totalTaxesAmount {
			value
		}
		discountedTaxesAmount {
			value
		}
		originalTaxesAmount {
			value
		}
		shippingAmount {
			value
		}
    }
}
`;

export const ADD_CHECKOUT_LINE_ITEM = `
mutation addCheckoutLineitem($itemId: ID!, $checkoutId: ID!) {
	addCheckoutLineItem(itemId: $itemId, checkoutId: $checkoutId) {
		id
        user {
            id
            email
            phone
            firstName
            lastName
        }
        billingAddress {
            address {
                id
                name
                street
                streetNumber
                internalNumber
                neighborhood
                city
                state
                country
                zipCode
                geolocation {
                    lat
                    lng
                }
            }
            legalName
            taxId
        }
        shippingAddress {
            address {
                id
                name
                street
                streetNumber
                internalNumber
                neighborhood
                city
                state
                country
                zipCode
                geolocation {
                    lat
                    lng
                }
            }
            email
            phone
        }
        lineItems {
			id
            name
            sku
            product {
                id
                name
                description
				mainImageUrl
            }
            productVariant {
                id
                name
                amount {
                    value
                }
            }
            quantity
            provider {
                id
                name
				coverages {
					id
					name
				}
            }
        }
        totalAmount {
            value
        }
        discounts {
            id
            code
            amount {
                value
            }
        }
        discountedAmount {
            value
        }
        originalAmount {
            value
        }
        totalTaxesAmount {
            value
        }
        discountedTaxesAmount {
            value
        }
        originalTaxesAmount {
            value
        }
        shippingAmount {
            value
        }
	}
}
`;

export const GET_CHECKOUT = `
query getCheckout($id: ID!) {
    getCheckout(id: $id) {
        id
        user {
            id
            email
            phone
            firstName
            lastName
            document
        }
        billingAddress {
            address {
                id
                name
                street
                streetNumber
                internalNumber
                neighborhood
                city
                state
                country
                zipCode
                geolocation {
                    lat
                    lng
                }
            }
            legalName
            taxId
        }
        shippingAddress {
            address {
                id
                name
                street
                streetNumber
                internalNumber
                neighborhood
                city
                state
                country
                zipCode
                geolocation {
                    lat
                    lng
                }
            }
            email
            phone
        }
        lineItems {
            name
            sku
            product {
                id
                name
                description
				mainImageUrl
            }
            productVariant {
                id
                name
                amount {
                    value
                }
            }
            quantity
            provider {
                id
                name
            }
        }
        totalAmount {
            value
        }
        discounts {
            id
            code
            amount {
                value
            }
        }
        discountedAmount {
            value
        }
        originalAmount {
            value
        }
        totalTaxesAmount {
            value
        }
        discountedTaxesAmount {
            value
        }
        originalTaxesAmount {
            value
        }
        shippingAmount {
            value
        }
    }
}
`;
