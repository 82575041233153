import React, { useContext, useEffect, useState } from 'react';
import { Flex, Box, Text, Button, Image, Input } from 'theme-ui';
import {
	IoIosAdd,
	IoIosRemove,
	IoIosCloseCircle,
	IoIosCreate,
	IoIosCheckmark,
	IoIosClose,
} from 'react-icons/io';
import styles from './line-item.style';
import { LocalCartContext } from '../../../provider/local-cart-provider';
import { getPrice } from '../../../utils/index';

const LineItem = (props: any) => {
	const { item } = props;

	const lineItemQuantity = item ? item.quantity : 0;
	const { updateCart } = useContext(LocalCartContext);
	const [quantity, setQuantity] = useState(0);
	const [tempQuantity, setTempQuantity] = useState(0);
	const [isQuantityEditing, setIsQuantityEditing] = useState(false);
	const totalPrice = item.amount.value * item.quantity;
	const unitPrice = item.amount.value;
	const currency = item.amount.currency;

	useEffect(() => {
		setQuantity(item.quantity);
		setTempQuantity(item.quantity);
	}, [item]);

	const handleQuantityChange = (quantity: number) => {
		if (quantity < 1) {
			setIsQuantityEditing(false);
		}
		updateCart(item.productVariantId, quantity);
	};

	const handleRemove = () => {
		handleQuantityChange(0);
	};

	const handleEdit = () => {
		setIsQuantityEditing(true);
	};

	const handleCancelEdit = () => {
		setIsQuantityEditing(false);
		setTempQuantity(item.quantity);
	};

	const handleConfirmEdit = () => {
		handleQuantityChange(tempQuantity);
		setIsQuantityEditing(false);
	};

	return (
		<>
			{lineItemQuantity > 0 ? (
				<Flex sx={styles.wrapper}>
					<Box sx={styles.imageWrapper}>
						{item.product.mainImageUrl && (
							<Image src={item.product.mainImageUrl} alt={item.name} />
						)}
						<Button variant="text" title="Delete" onClick={() => handleRemove()}>
							<IoIosCloseCircle />
						</Button>
					</Box>
					<Box sx={styles.contentWrapper}>
						<Box>
							<Text sx={styles.title}>{item.product.name}</Text>
							<Text sx={styles.unitPrice}>
								Precio por unidad: {getPrice(unitPrice, currency)}
							</Text>
						</Box>
						<Box sx={styles.counterWrapper}>
							{isQuantityEditing ? (
								<Box sx={styles.cartCounter}>
									<Input
										type="number"
										value={tempQuantity}
										onChange={(e) =>
											setTempQuantity(parseInt(e.target.value, 10))
										}
									/>
									<Button title="Confirmar" onClick={handleConfirmEdit}>
										<IoIosCheckmark />
									</Button>
									<Button title="Cancelar" onClick={handleCancelEdit}>
										<IoIosClose />
									</Button>
								</Box>
							) : (
								<Box sx={styles.cartCounter}>
									<Button
										title="Decrement"
										onClick={() => handleQuantityChange(item.quantity - 1)}
									>
										<IoIosRemove />
									</Button>
									<Box>{quantity}</Box>
									<Button
										title="Increment"
										onClick={() => handleQuantityChange(item.quantity + 1)}
									>
										<IoIosAdd />
									</Button>
									<Button title="Editar" onClick={handleEdit}>
										<IoIosCreate />
									</Button>
								</Box>
							)}
							<Box id="total">
								<Text sx={styles.totalPrice}>{getPrice(totalPrice, currency)}</Text>
							</Box>
						</Box>
					</Box>
				</Flex>
			) : null}
		</>
	);
};

export default LineItem;
