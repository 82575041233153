import { CheckoutState, CheckoutDetails } from '../../types/checkoutTypes';

type CheckoutAction =
	| {
			type: 'CREATE_LINE_ITEM';
			payload: string;
	  }
	| {
			type: 'CREATE_CHECKOUT';
			payload: CheckoutDetails;
	  }
	| {
			type: 'GET_CHECKOUT';
			payload: CheckoutDetails;
	  };

const categoryReducer = (globalState: CheckoutState, action: CheckoutAction): CheckoutState => {
	switch (action.type) {
		case 'CREATE_LINE_ITEM':
			return {
				...globalState,
				lineItems: [...globalState.lineItems, action.payload],
			};
		case 'CREATE_CHECKOUT':
			return {
				...globalState,
				CheckoutDetails: action.payload,
			};
		case 'GET_CHECKOUT':
			return {
				...globalState,
				CheckoutDetails: action.payload,
			};

		default:
			return globalState;
	}
};
export default categoryReducer;
