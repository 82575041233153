import { createContext } from 'react';
import { CheckoutDetails } from '../../types/checkoutTypes';

export type CheckoutContextProps = {
	checkoutId: string;
	lineItems: string[];
	billingAddressId: string;
	shippingAddressId: string;
	userId: string;
	CheckoutDetails: CheckoutDetails;
	createLineItem: Function;
	createCheckout: Function;
	addCheckoutLineItem: Function;
	getCheckout: Function;
	getProviderInfo: Function;
};
const CheckoutContext = createContext<CheckoutContextProps>({} as CheckoutContextProps);
export default CheckoutContext;
