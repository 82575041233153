export const GET_PRODUCTS = `
query getProducts {
	getProducts {
		id
		name
		description
		mainImageUrl
		imageUrls
        brand
        category
        activeIngredients
        cropTypes
	}
}
`;
export const GET_PRODUCTS_VARIANTS = `
query getProductVariants {
	getProductVariants {
		id
        sku
        name
        inventory
        presentation
        provider {
            id
            name
            coverages {
                id
                name
            }
            address {
                street
                streetNumber
                internalNumber
                neighborhood
                city
                state
                country
                zipCode
                geolocation {
                    lat
                    lng
                }
            }
            email
            phone
        }
        amount {
            value
            currency
        }
        publicAmount {
            value
            currency
        }
        product {
            id
            name
            description
            mainImageUrl
            imageUrls
            brand
            category
            activeIngredients
            cropTypes
        }
	}
}
`;
export const GET_CATEGORIES = `
query getCollections($id: ID!) {
	getCollections(coverageId: $id) {
		id
		name
		description
		productVariants
		coverageId
	}
}
`;
export const GET_PRODUCTS_BY_ID = `
query getProductVariantById($id: ID!) {
	getProductVariantById(productVariantId: $id) {
        id
        sku
        name
        inventory
        presentation
        provider {
            id
            name
            coverages {
                id
                name
            }
            address {
                street
                streetNumber
                internalNumber
                neighborhood
                city
                state
                country
                zipCode
                geolocation {
                    lat
                    lng
                }
            }
            email
            phone
        }
        amount {
            value
            currency
        }
        publicAmount {
            value
            currency
        }
        product {
            id
            name
            description
            mainImageUrl
            imageUrls
            brand
            category
            activeIngredients
            cropTypes
        }
	}
}
`;
export const GET_CATEGORY = ` 
query getCollection($collectionId: ID!, $coverageId: ID) {
	getCollection(collectionId: $collectionId, coverageId: $coverageId) {
		id
		name
		description
		productVariants
		coverageId
	}
}
`;
export const GET_PROVIDER_BY_LOCATION = `
query getProvidersByLocation($geolocation: PointInput!) {
	getProvidersByLocation(geolocation: $geolocation) {
		id
		name
		phone
		email
		distance
		coverages {
			id
			name
		}
		address {
			street
			streetNumber
			internalNumber
			neighborhood
			city
			state
			country
			zipCode
			geolocation {
				lat
				lng
			}
		}
	}
}
`;
export const GET_PRODUCT_VARIANTS_BY_PROVIDER = `
query getProductVariantsByProvider($id: ID!) {
	getProductVariantsByProvider(providerId: $id) {
		id
        sku
        name
        inventory
        presentation
        provider {
            id
            name
            coverages {
                id
                name
            }
            address {
                street
                streetNumber
                internalNumber
                neighborhood
                city
                state
                country
                zipCode
                geolocation {
                    lat
                    lng
                }
            }
            email
            phone
        }
        amount {
            value
            currency
        }
        publicAmount {
            value
            currency
        }
        product {
            id
            name
            description
            mainImageUrl
            imageUrls
            brand
            category
            activeIngredients
            cropTypes
        }
	}
}
`;

export const APPLY_FILTERS = `
query applyFilters($input: FiltersInput!) {
    applyFilters(filters: $input) {
        id
        sku
        name
        inventory
        presentation
        provider {
            id
            name
            coverages {
                id
                name
            }
            address {
                street
                streetNumber
                internalNumber
                neighborhood
                city
                state
                country
                zipCode
                geolocation {
                    lat
                    lng
                }
            }
            email
            phone
        }
        amount {
            value
            currency
        }
        publicAmount {
            value
            currency
        }
        product {
            id
            name
            description
            mainImageUrl
            imageUrls
            brand
            category
            activeIngredients
            cropTypes
        }
    }
}
`;
