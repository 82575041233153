import React, { useReducer } from 'react';
import CheckoutContext from './checkout-context';
import checkoutReducer from './checkout-reducer';
import { CREATE_LINE_ITEM } from '../../helper/orders/create-line-item';
import { ProductLineItem } from '../../types/productTypes';
import { UserDetails } from '../../types/userTypes';
import {
	ADD_CHECKOUT_LINE_ITEM,
	CREATE_CHECKOUT,
	GET_CHECKOUT,
} from '../../helper/orders/checkout';
import { CheckoutInput, CheckoutState } from '../../types/checkoutTypes';
import { GET_PROVIDER } from '../../helper/providers/get-provider';
import LineItem from '../../components/cart/line-item/line-item';
interface props {
	children: JSX.Element | JSX.Element[];
}

const CheckoutProvider = ({ children }: props) => {
	const initialState: CheckoutState = {
		checkoutId: '',
		lineItems: [],
		billingAddressId: '',
		shippingAddressId: '',
		userId: '',
		CheckoutDetails: {
			checkoutId: '',
			user: {
				email: '',
				firstName: '',
				lastName: '',
				phone: '',
				id: '',
			},
			billingAddress: {},
			shippingAddress: {},
			lineItems: [],
			totalAmount: { value: '' },
			discounts: [],
			discountedAmount: {},
			originalAmount: {},
			totalTaxesAmount: {
				value: '',
			},
			discountedTaxesAmount: {},
			originalTaxesAmount: {},
			shippingAmount: {},
		},
	};

	const [globalState, dispatch] = useReducer(checkoutReducer, initialState);

	const createLineItem = async (product: ProductLineItem, userDetails: UserDetails) => {
		const resCreateLineItem = await window.fetch(`${process.env.GATSBY_API_URL}/orders/`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${userDetails.token}`,
			},
			body: JSON.stringify({
				query: CREATE_LINE_ITEM,
				variables: {
					input: product,
				},
			}),
		});

		try {
			const res = await resCreateLineItem.json();
			const itemId = res.data.createLineItem.id;
			dispatch({
				type: 'CREATE_LINE_ITEM',
				payload: itemId,
			});
			return itemId;
		} catch (error) {
			console.log(error);
		}
	};
	const createCheckout = async (data: CheckoutInput, userDetails: UserDetails) => {
		const resCreateCheckout = await window.fetch(`${process.env.GATSBY_API_URL}/orders/`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${userDetails.token}`,
			},
			body: JSON.stringify({
				query: CREATE_CHECKOUT,
				variables: {
					input: data,
				},
			}),
		});
		try {
			const checkoutData = await resCreateCheckout.json();

			dispatch({
				type: 'CREATE_CHECKOUT',
				payload: checkoutData.data.createCheckout,
			});

			return checkoutData.data.createCheckout;
		} catch (error) {
			console.log(error);
		}
	};
	const addCheckoutLineItem = async (
		itemId: string,
		checkoutId: string,
		userDetails: UserDetails,
	) => {
		const resAddCheckoutLineItem = await window.fetch(`${process.env.GATSBY_API_URL}/orders/`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${userDetails.token}`,
			},
			body: JSON.stringify({
				query: ADD_CHECKOUT_LINE_ITEM,
				variables: {
					itemId: itemId,
					checkoutId: checkoutId,
				},
			}),
		});

		try {
			const dataAddItem = await resAddCheckoutLineItem.json();
			return dataAddItem;
		} catch (error) {
			console.log(error);
		}
	};

	const getCheckout = async (checkoutId: string, userDetails: UserDetails) => {
		const resCheckoutData = await window.fetch(`${process.env.GATSBY_API_URL}/orders/`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `${userDetails.token}`,
			},
			body: JSON.stringify({
				query: GET_CHECKOUT,
				variables: { id: checkoutId },
			}),
		});

		const responseData = await resCheckoutData.json();
		const checkoutData = responseData?.data?.getCheckout;

		dispatch({
			type: 'GET_CHECKOUT',
			payload: checkoutData,
		});

		return checkoutData;
	};

	const getProviderInfo = async (providerId: string) => {
		const resProvider = await window.fetch(`${process.env.GATSBY_API_URL}/coverage/`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: GET_PROVIDER,
				variables: {
					id: providerId,
				},
			}),
		});

		try {
			const providerData = await resProvider.json();
			return providerData.data.getProviderById;
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<CheckoutContext.Provider
			value={{
				checkoutId: globalState.checkoutId,
				lineItems: globalState.lineItems,
				billingAddressId: globalState.billingAddressId,
				shippingAddressId: globalState.shippingAddressId,
				userId: globalState.userId,
				CheckoutDetails: globalState.CheckoutDetails,
				createLineItem,
				createCheckout,
				addCheckoutLineItem,
				getCheckout,
				getProviderInfo,
			}}
		>
			{children}
		</CheckoutContext.Provider>
	);
};

export default CheckoutProvider;
